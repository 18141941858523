
  import { defineComponent, onMounted, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useI18n } from "vue-i18n";
  import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import * as Yup from "yup";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import store from "@/presentation/store";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { UpdateUserModel } from "@/domain/user/updateUser/model/UpdateUserModel";
  
  export default defineComponent({
    name: "Profilim",
    components: { Field, Form, ErrorMessage },
    setup() {
      const { t, te } = useI18n();
      const swalNotification = new SwalNotification();
      const scrollElRef = ref<null | HTMLElement>(null);
      const submitButton = ref<HTMLElement | null>(null);
      const userController = store.state.ControllersModule.userController;
      const updateUserModel = ref<UpdateUserModel>({});
  
      //Create form validation object
      const infoForm = Yup.object().shape({
        name: Yup.string().required(t("validators_error.required")).label("Name"),
        surname: Yup.string()
          .required(t("validators_error.required"))
          .label("Surname"),
        company: Yup.string()
          .required(t("validators_error.required"))
          .label("Company"),
      });
  
      const getUserInfo = () => {
        userController
          .aboutUser()
          .then((response) => {
            if (response.isSuccess) {
              updateUserModel.value.name = response.getValue().name;
              updateUserModel.value.surname = response.getValue().surname;
              updateUserModel.value.tenantName = response.getValue().tenantName;
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      //Form submit function
      const onSubmit = (values) => {
        // Activate loading indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
  
        userController
          .updateUser(updateUserModel.value)
          .then((response) => {
            if (response.isSuccess) {
              submitButton.value?.removeAttribute("data-kt-indicator");
  
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_USER),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else return text;
      };
  
      onMounted(() => {
        getUserInfo();
        setCurrentPageTitle(t("modals.detail.pageTitle"));
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0;
        }
      });
  
      return {
        infoForm,
        PAGE_TITLE_PATH,
        translate,
        onSubmit,
        updateUserModel,
      };
    },
  });
  